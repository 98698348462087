import ky from 'ky';
import sound from '../sounds/cork.json';

const speech = new Audio();
speech.autoplay = true;

const init = () => (speech.src = `data:audio/wav;base64,${sound.base64}`);

const say = async ({ text, readyToPlay, speakingRate, speakingVoice }) => {
  return new Promise(async (resolve, reject) => {
    let response;

    try {
      response = await ky
        .post(
          `https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
          {
            json: {
              audioConfig: {
                audioEncoding: 'LINEAR16', //   audioEncoding: 'MP3', // Request MP3 format
                speakingRate
              },
              input: {
                text
              },
              voice: speakingVoice
            }
          }
        )
        .json();
    } catch (error) {
      const errorDetails = await error.response.json();
      return reject(errorDetails);
    }

    speech.src = `data:audio/wav;base64,${response.audioContent}`;
    speech.addEventListener('ended', () => {
      resolve(response.audioContent); // Return the base64 encoded audio content
    });
    speech.addEventListener('canplaythrough', event => {
      readyToPlay(speech);
      speech.play();
    });
  });
};

export { say, init };
